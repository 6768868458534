@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom CSS */
.hide-scrollbar {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.hide-scrollbar::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
}

/* For WebKit browsers (Chrome, Safari, Edge) */
#carousel-container::-webkit-scrollbar {
    height: 3px;
}

#carousel-container::-webkit-scrollbar-thumb {
    background: #003d3b;
}

#carousel-container::-webkit-scrollbar-track {
    background: #e2e2e2;
}
